:root{
    --primary-color: #628BA0;
   
  }
  
  .counter-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px 15px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  @media (max-width: 454px) {
    .counter-container {
      padding: 0 80px;
    }
  }
  @media (max-width: 400px) {
    .counter-container {
      padding: 0 30px;
    }
  }
  @media (max-width: 302px) {
    .counter-container {
      padding: 0;
    }
  }
  .counter-rotary-holder {
    width: 95%;
    height: 95%;
    position: absolute;
    transform: rotate(45deg);
    z-index: 5;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    animation: rotate 6s infinite linear;
  }
  
  .counter-content-holder {
    background-color:  #1E1E1E;
    width: 75px;
    height: 75px;
    border-radius: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .dark .counter-circle{
    background-color: rgb(5, 13, 21);
  }
  .dark .counter-content-holder{
    background: linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36));
    
  }
  .dark .counter-text, .dark .counter-text-value{
    color: #fff;
  }
  .counter-rotary-item {
    width: 15px;
    height: 15px;
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    position: absolute;
    background-color:  #1E1E1E;
  }
  .counter-circle {
    width: 88px;
    height: 88px;
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: #1E1E1E;
    position: relative;
  }
  
  
  .counter-text {
    font-family: "Jotia-Regular";
    font-size: 12px;
    line-height: 18px;
    color: #FFF;
    z-index: 1;
  }
  
  .counter-text-value {
    font-family: "Jotia-Regular";
    font-size: 27px;
    color: #020000;
    letter-spacing: -2px;
    line-height: 32px;
  }
  
  .disabled .counter-rotary-item, .disabled .counter-circle{
    border-color:black;
  }

  @keyframes rotate {
    from {
      transform: rotate(45deg);
    }
   to {
      transform: rotate(405deg);
    }
  }