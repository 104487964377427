body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body iframe {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Jotia-Regular';
  src: local('Jotia-Regular'), url(./assets/fonts/Jotia-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Schnyder-M-App';
  src: local('Schnyder-M-App'),
    url(./assets/fonts/SchnyderM-Light-App.ttf) format('truetype');
}