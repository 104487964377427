.center-div {
    margin: auto;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    height: 100%;
    display: flex;
}
.nft-list{
    scrollbar-color: #628BA0 #FFF;
    scrollbar-width: thin;
}
.nft-list::-webkit-scrollbar {
    width: 5px;
    background-color: #FFF; 
  }
  .nft-list::-webkit-scrollbar-thumb {
    background-color: #628BA0;
  }